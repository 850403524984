/* screenwidth less than 900px
--------------------------------------------------------------------- */
@media only screen and (max-width: 900px) {
  /* nav-wrap */
  #nav-wrap {
    font: 11px "Open Sans", sans-serif;
    letter-spacing: 1.5px;
  }

  /* About Section
   ------------------------------------------------------------------- */
  #about .profile-pic {
    width: 114px;
    height: 114px;
    margin-left: 12px;
  }
  #about .contact-details {
    width: 50%;
  }
  #about .download {
    width: 50%;
  }
}

/* mobile wide/smaller tablets
---------------------------------------------------------------------- */

@media only screen and (max-width: 767px) {
  /* mobile navigation
   -------------------------------------------------------------------- */
  #nav-wrap {
    font-size: 12px;
    background: transparent !important;
    letter-spacing: 1.5px;
    width: auto;
    position: fixed;
    top: 0;
    right: 0;
  }

  #nav-wrap > .menu-open {
    position: absolute;
    font-size: 2.5em;
    color: #262f39;
    top: 12px;
    right: 20px;
  }
  #nav > .mobile-btn {
    /* position: absolute; */
    font-size: 2.5em;
    color: #262f39;
    top: 12px;
    right: 20px;
    float: right;
  }
  .mobile-btn:visited {
    color: #262f39;
  }

  /* #nav-wrap > a:before,
  #nav-wrap > a:after {
    position: absolute;
    border: 2px solid #fff;
    top: 35%;
    left: 25%;
    right: 25%;
    content: "";
  } */
  #nav-wrap > a:after {
    top: 60%;
  }

  /* toggle buttons */
  #nav-wrap:not(:target) > a:first-of-type,
  #nav-wrap:target > a:last-of-type {
    display: block;
  }
  #nav:not(:target) > a:first-of-type,
  #nav:target > a:last-of-type {
    display: block;
  }

  /* hide menu panel */
  #nav-wrap ul#nav {
    height: auto;
    display: none;
    clear: both;
    width: auto;
    float: right;

    position: relative;
    top: 12px;
    right: 0;
  }

  /* display menu panels */
  #nav-wrap:target > ul#nav {
    display: block;
    padding: 10px 20px 70px 20px;
    background: #fff;
    margin: 20px 20px;
    clear: both;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 1em;
  }

  ul#nav li {
    width: 100%;
    display: block;
    height: auto;
    margin: 0 auto;
    padding: 0 4%;
    text-align: left;
    /* border-bottom: 1px solid #2d2e34;
    border-bottom-style: dotted; */
  }

  ul#nav li a {
    display: block;
    margin: 0;
    padding: 0;
    margin: 12px 0;
    line-height: 16px; /* reset line-height from 48px */
    border: none;
  }

  /* header social links */
  header .social {
    margin: 18px 0 24px 0;
    font-size: 24px;
    line-height: 36px;
  }
  header .social li {
    margin: 0 10px;
  }

  /* scrolldown link */
  header .scrolldown {
    display: none;
  }

  /* Control Nav */
  .flex-control-nav {
    text-align: center;
    margin-left: -30px;
  }

  /* footer
   ------------------------------------------------------------------------ */

  /* copyright */
  footer .copyright li:before {
    content: none;
  }
  footer .copyright li {
    margin-right: 12px;
  }

  /* social links */
  footer .social-links {
    font-size: 22px;
  }
  footer .social-links li {
    margin-left: 18px;
  }

  /* Go To Top Button */
  #go-top {
    margin-left: -22px;
  }
  #go-top a {
    width: 54px;
    height: 54px;
    font-size: 18px;
    line-height: 54px;
  }
}

/* mobile narrow
  -------------------------------------------------------------------------- */

@media only screen and (max-width: 480px) {
  /* mobile navigation
   -------------------------------------------------------------------- */
  #nav-wrap ul#nav {
    width: auto;
    float: none;
  }

  /* header social links */
  header .social {
    font-size: 20px;
  }
  header .social li {
    margin: 0 6px;
  }

  /* footer
   ------------------------------------------------------------------------ */

  /* social links */
  footer .social-links {
    font-size: 20px;
  }
  footer .social-links li {
    margin-left: 14px;
  }
}
