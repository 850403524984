.schedule-container {
  margin: 0 auto;
  width: min(360px, 80vw);
}
.schedule-item {
  display: flex;
  justify-content: stretch;
}

.item-name {
  white-space: nowrap;
}
.direction {
  line-height: 1.15em;
  margin-bottom: 0.8em;
}
.time {
  white-space: nowrap;
  color: #313131;
}
.item-dots {
  height: 11px;
  flex: 100% 1;
  border-bottom: 2px dotted #ccc;
  margin-left: 9px;
  margin-right: 9px;
}
