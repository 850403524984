body {
  background: #0f0f0f;
  font-family: "Open Sans", sans-serif;
}

.App {
  background-color: rgb(237, 237, 237);
}
/* ------------------------------------------------------------------ */
/* b. Header Styles
  /* ------------------------------------------------------------------ */

.home {
  position: relative;
  width: 100%;
  text-align: center;
}

/* vertically center banner section */
.home:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.home .banner {
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 85%;
  padding-bottom: 30px;
  text-align: center;
  color: black;
}

.home .banner-text {
  width: 100%;
  font-weight: 600;
}
.home .banner {
  padding-top: 24px;
}
.home .banner {
  padding-top: 24px;
}
.home .banner-text h1 {
  font-size: 4em;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin: 12px auto 22px auto;
  font-family: "Fraunces";
  color: #357f3e;
}

.lec {
  font-family: "Fraunces";
}

.home .banner-text h3 {
  font-size: 2.4rem;
  letter-spacing: 0.01em;
  color: #225828;
  margin: 0 auto;
  width: 70%;
  font-weight: 500;
}
.home .banner-text h3 > span {
  color: #357f3e;
}

.home sub {
  font-size: 1em;
  font-weight: 400;
  color: #225828;
}
.polaroid {
  margin-top: 2em;
  max-width: min(500px, 90vw);
  margin: auto;
}

#welcome {
  line-height: 1.6em;
  text-align: center;
  font-size: 1em;
  color: rgb(48, 74, 57);
  font-weight: 400;
}

/* scrolldown link */
.home .scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.home .scrolldown a:hover {
  color: #f5ad88;
}

/* primary navigation
  --------------------------------------------------------------------- */
#nav-wrap ul,
#nav-wrap li,
#nav-wrap a {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

/* nav-wrap */
#nav-wrap {
  font: 12px "Open Sans", sans-serif;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
}
/* nav-wrap */

.opaque {
  background-color: #fff;
}

/* hide toggle button */
#nav-wrap > a.mobile-btn {
  display: none;
}
#nav > a.mobile-btn {
  display: none;
}

ul#nav {
  min-height: 7rem;
  width: auto;

  /* center align the menu */
  text-align: center;
}
ul#nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
}

/* Links */
ul#nav li a {
  /* 8px padding top + 8px padding bottom + 32px line-height = 48px */

  display: inline-block;
  padding: 2.2rem 1.2em 2.2rem 1.2em;
  line-height: 32px;
  letter-spacing: 1px;
  text-transform: none;
  text-decoration: none;
  text-align: left;
  color: #595959;

  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

ul#nav li a:active {
  background-color: transparent !important;
}
ul#nav li.current a {
  color: #357f3e;
  font-weight: 700;
}

/* ------------------------------------------------------------------ */
/* c. what-is-it Section
  /* ------------------------------------------------------------------ */

#seq-sisters {
  font-size: 4em;
  margin-bottom: 12px;
  width: 100%;
  color: #357f3e;
  font-family: "Fraunces", serif;
  font-weight: 600;
  line-height: 1.1em;
}
y #calendar h2 {
  color: #262f39;
}

#what-to-bring p,
#calendar p,
#where-is-it p {
  line-height: 1.1em;
  font-size: 1em;
  color: #262f39;
}

#what-to-bring li {
  color: #262f39;
}
#what-to-bring label {
  font: 12px "Open Sans", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2.5px;
}

#where-is-it {
  background: #fff;
  padding-top: 15vh;
  padding-bottom: 20vh;
  overflow: hidden;
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
}

.camp-image-container {
  gap: 40px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .camp-image-container {
    flex-direction: column;
    gap: 20px;
  }
}

.camp-image {
  width: min(500px, 90vw);

  overflow: hidden;
  color: #d5bd98;
  /* margin: 0 auto; */

  object-fit: contain;

  padding: 15px 15px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 1px 4px 8px rgb(122 133 124 / 40%);
}

#what-to-bring ul {
  list-style-type: disc;
}
#what-to-bring {
  background: #fff;
  padding-top: 80px;
  padding-bottom: 5vh;
  overflow: hidden;
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
}

.wave {
  left: 0;
  right: 0;
  top: 0;
  height: 61rem;
  position: absolute;
  background-color: rgba(27, 191, 253, 0.2);
  background-image: url(https://static-cse.canva.com/_next/static/assets/wave.2881x801.e3004027281d91aea8440556fa84fbfd.png);
  background-repeat: no-repeat;
  background-position: bottom;
}

.register-btn {
  color: #ffffff;
  font-size: 2em;
  font-weight: 800;
  letter-spacing: 0px;
  padding: 20px 30px;
  background: #357f3e;
  box-shadow: none;
  font-family: "Fraunces";
  font-weight: 300;
}

#register,
#booking-success,
#calendar {
  background: #f4f9f5;
  padding-top: 20vh;
  padding-bottom: 20vh;
  overflow: hidden;
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  text-align: center;
}

#registration-container {
  height: 100vh;
  padding-top: 70px;
}
#registration-frame-container {
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%23262f39" x="50%" y="50%" font-family="\'Open Sans\', sans-serif" font-size="24" text-anchor="middle">Loading...</text></svg>')
    0px 0px no-repeat;
}
#registration-frame-frame-container {
  background: #f4f9f5;
}

#calendar .time {
  color: #5567c0;
  font-weight: bold;
}

.logo-image {
  padding-top: 20px;
  width: 2.5em;
  position: absolute;
  left: 20px;
}

/* popup modal */
.popup-modal {
  max-width: 550px;
  background: #fff;
  position: relative;
  margin: 0 auto;
}
.popup-modal .description-box {
  padding: 12px 36px 18px 36px;
}
.popup-modal .description-box h4 {
  font: 15px/24px "Open Sans", sans-serif;
  margin-bottom: 12px;
  color: #111;
}
.popup-modal .description-box p {
  font: 14px/24px "Open Sans", sans-serif;
  color: #a1a1a1;
  margin-bottom: 12px;
}
.popup-modal .description-box .categories {
  font: 11px/21px "Open Sans", sans-serif;
  color: #a1a1a1;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: block;
  text-align: left;
}
.popup-modal .description-box .categories i {
  margin-right: 8px;
}
.popup-modal .link-box {
  padding: 18px 36px;
  background: #111;
  text-align: left;
}
.popup-modal .link-box a {
  color: #fff;
  font: 11px/21px "Open Sans", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}
.popup-modal a:hover {
  color: #00cccc;
}
.popup-modal a.popup-modal-dismiss {
  margin-left: 24px;
}

/* fadein/fadeout effect for modal popup
  /* ------------------------------------------------------------------ */

/* content at start */
.mfp-fade.mfp-wrap .mfp-content .popup-modal {
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
/* content fadein */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content .popup-modal {
  opacity: 1;
}
/* content fadeout */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content .popup-modal {
  opacity: 0;
}

/* ------------------------------------------------------------------ */
/* f. Call To Action Section
  /* ------------------------------------------------------------------ */

#call-to-action {
  background: #212121;
  padding-top: 66px;
  padding-bottom: 48px;
}
#call-to-action h1 {
  font: 18px/24px "Open Sans", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
}
#call-to-action h1 span {
  display: none;
}
#call-to-action .header-col h1:before {
  font-family: "FontAwesome";
  content: "\f0ac";
  padding-right: 10px;
  font-size: 72px;
  line-height: 72px;
  text-align: left;
  float: left;
  color: #fff;
}
#call-to-action .action {
  margin-top: 12px;
}
#call-to-action h2 {
  font: 28px/36px "Open Sans", sans-serif;
  color: #f4f9f5;
  margin-bottom: 6px;
}
#call-to-action h2 a {
  color: inherit;
}
#call-to-action p {
  color: #636363;
  font-size: 17px;
}
/*#
  call-to-action .button {
      color:#fff;
     background: #0D0D0D;
  }
  */
#call-to-action .button:hover,
#call-to-action .button:active {
  background: #ffffff;
  color: #0d0d0d;
}
#call-to-action p span {
  font-family: "Open Sans-semibold", sans-serif;
  color: #d8d8d8;
}

/* Flex Slider
  /* ------------------------------------------------------------------ */

/* Reset */
.flexslider a:active,
.flexslider a:focus {
  outline: none;
}
.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.slides li {
  margin: 0;
  padding: 0;
}

/* Necessary Styles */
.flexslider {
  position: relative;
  zoom: 1;
  margin: 0;
  padding: 0;
}
.flexslider .slides {
  zoom: 1;
}
.flexslider .slides > li {
  position: relative;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}
/* Suggested container for slide animation setups. Can replace this with your own */
.flex-container {
  zoom: 1;
  position: relative;
}

/* Clearfix for .slides */
.slides:before,
.slides:after {
  content: " ";
  display: table;
}
.slides:after {
  clear: both;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
   * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block;
}

/* Slider Styles */
.slides {
  zoom: 1;
}
.slides > li {
  /*margin-right: 5px; */
  overflow: hidden;
}

/* Control Nav */
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -20px;
  text-align: left;
}
.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.flex-control-paging li a {
  width: 12px;
  height: 12px;
  display: block;
  background: #ddd;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.3);
}
.flex-control-paging li a:hover {
  background: #ccc;
  background: rgba(255, 255, 255, 0.7);
}
.flex-control-paging li a.flex-active {
  background: #fff;
  background: rgba(255, 255, 255, 0.9);
  cursor: default;
}

/* ------------------------------------------------------------------ */
/* i. Footer
  /* ------------------------------------------------------------------ */

footer {
  padding-top: 10rem;
  padding-bottom: 6rem;
  color: #303030;
  font-size: 14px;
  text-align: center;
  position: relative;
  background: white;
}

footer a,
footer a:visited {
  color: #525252;
}
footer a:hover,
footer a:focus {
  color: #fff;
}

/* copyright */
footer .copyright {
  margin: 0;
  padding: 0;
  color: black;
  font-size: 12px;
}
footer .copyright li {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 24px;
}
.ie footer .copyright li {
  display: inline;
}
footer .copyright li:before {
  content: "\2024";
  padding-left: 10px;
  padding-right: 10px;
  color: #0c0b0c;
}
footer .copyright li:first-child:before {
  display: none;
}

/* social links */
footer .social-links {
  margin: 18px 0 30px 0;
  padding: 0;
  font-size: 30px;
}
footer .social-links li {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: 42px;
  color: #357f3e;
}

footer .social-links li:first-child {
  margin-left: 0;
}

/* Go To Top Button */
#go-top {
  position: absolute;
  top: -24px;
  left: 50%;
  margin-left: -30px;
}
#go-top a {
  text-decoration: none;
  border: 0 none;
  display: block;
  width: 60px;
  height: 60px;
  background-color: #357f3e;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  color: #fff;
  font-size: 21px;
  line-height: 60px;
  border-radius: 100%;
}
#go-top a:hover {
  background-color: #0f9095;
}
